import { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import logo from './logo.png';
import './App.css';
import Main from './Components/Main.js'
import Header from './Components/Header.js'
import Shop from './Components/Shop.js'
import Product from './Components/Product.js'
import Cart from './Components/Cart.js'
import Checkout from './Components/Checkout.js'
import Success from './Components/Success.js'
import Quote from './Components/Quote.js'
import LoginModal from './Components/LoginModal.js'
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore,deleteDoc,updateDoc, setDoc, collection, query, where, doc, getDoc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './Components/firebase.js';


function App() {

  const auth = getAuth()
  const functions = getFunctions(firebase);
  const db = getFirestore();

  const [user, setUser] = useState(null); 

  const [openMenu, setOpenMenu] = useState(false); 
  const [openNavMenu, setOpenNavMenu] = useState(false); 
  const [showLogin, setShowLogin] = useState(false);
  const [currCompany, setCurrCompany] = useState('')
  const [userData, setUserData] = useState({})

  const userDataRef = useRef()
  const parallax = useRef(null); 
  const navbarRef = useRef(null);
  const scrollRef = useRef(0);
  const header = useRef(null);
  const timeout = useRef(null);

  const sticky = useRef(0);
  const stickyHeight = useRef(0);
  const stickyTop  = useRef(0);
  
  const [showLogo, setShowLogo] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [openDrop, setOpenDrop] = useState(false);
  const [showOutlet, setShowOutlet] = useState(false);
  const [cartObj, setCartObj] = useState({});
  const [quoteObj, setQuoteObj] = useState({});
  const [orderObj, setOrderObj] = useState({"Id":"","Open":true,"Status":"Quote",'splitOrder':false,'totalQuantity':0,"Customer PO Id":"","Customer":"","Customer Id":"","Date":"","Contact Person":"","Total":"","PartIds":[],"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Description 2":"","Quantity":"",'Price':'','Mark Up':'','Per Price':'','Per Cost':'','Cost':''}],"CustomerPartIds":[],'BillingInfo':{'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':''},'toShip':[{"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Quantity":"",'Price':'','Per Price':''}],'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':'','From Customer Id':'','From Contact Person':'','From Address':'','From Address 2':'','From City':'','From Zip':'','From State':'','From Country':'','Ship By Date':'','Carrier':'','Tracking':'','# Units':'','Weight':'','Freight':'','Status':''}]})
  const emptyObj = {"Id":"","Open":true,"Status":"Quote",'splitOrder':false,'totalQuantity':0,"Customer PO Id":"","Customer":"","Customer Id":"","Date":"","Contact Person":"","Total":"","PartIds":[],"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Description 2":"","Quantity":"",'Price':'','Mark Up':'','Per Price':'','Per Cost':'','Cost':''}],"CustomerPartIds":[],'BillingInfo':{'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':''},'toShip':[{"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Description 2":"","Quantity":"",'Price':'','Per Price':''}],'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':'','From Customer Id':'','From Contact Person':'','From Address':'','From Address 2':'','From City':'','From Zip':'','From State':'','From Country':'','Ship By Date':'','Carrier':'','Tracking':'','# Units':'','Weight':'','Freight':'','Status':''}]}
  const emptyToShipObj = {"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Description 2":"","Quantity":"",'Price':'','Per Price':''}],'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':'','From Customer Id':'','From Contact Person':'','From Address':'','From Address 2':'','From City':'','From Zip':'','From State':'','From Country':'','Ship By Date':'','Carrier':'','Tracking':'','# Units':'','Weight':'','Freight':'','Status':''}
  const company = 'Lion Industries';
  const [selected1, setSelected1] = useState('');

  useEffect(()=>{
    onAuthStateChanged(auth, (user1) => {
      if (user1) {
        // User is signed in, see docs for a list of available properties
        // const uid = user.uid;
        setUser(user1)
        console.log('fuck',user1)
        const q = doc(db,'users', user1.uid);
        const getD = async(q)=>{
          // setUserData({})

          userDataRef.current = onSnapshot(q, (snapshot) => {
              console.log(snapshot)
              const data = snapshot.data()
              setUserData(data)
              if(data!=undefined&&data['cart']!=undefined&&Object.keys(data['cart']).length>0&&Object.keys(cartObj).length==0){
                setCartObj(data['cart'])
              }
            
              if (snapshot.type === "added") {
                  console.log("New : ", snapshot.data());
                    
              }
              if (snapshot.type === "modified") {
                  console.log("Modified : ", snapshot.data());
                setUserData(snapshot.data())
              }
              // if (snapshot.type === "removed") {
              //     console.log("Removed : ", snapshot.doc.data());
              // }
          
          });
          
        }
        getD(q)
      } else {
        setUser(null)
        console.log(user)
        // User is signed out
      }
    });
    return ()=>{
        if(userDataRef.current!=null){
          userDataRef.current()
          userDataRef.current=null
        }
      }
  },[])
  
  // var provider = new firebase.auth.GoogleAuthProvider();
  const resize =()=>{
    stickyHeight.current=navbarRef.current.offsetHeight;
    // root.style.setProperty('--stickyHeight', stickyHeight.current+"px")
    document.documentElement.style.setProperty('--stickyHeight', stickyHeight.current+"px")
  }

  useEffect(()=>{

    // Get the offset position of the navbar
    // setSticky(navbarRef.current.offsetTop);
    stickyHeight.current=navbarRef.current.offsetHeight;
    sticky.current=navbarRef.current.offsetTop;
    stickyTop.current=stickyHeight.current + sticky.current;
    
    let root = document.documentElement;

    // setStickyTop(stickyHeight + sticky);
    root.style.setProperty('--stickyHeight', stickyHeight.current+"px")
    root.style.setProperty('--stickyHeightMin', -stickyHeight.current+"px")
    // console.log(stickyHeight.current)
    window.addEventListener("resize", resize);
    return ()=>{
      window.removeEventListener("resize", resize);
    }
  },[])
    

  useEffect(()=>{
    if(user!=null&&Object.keys(userData).length>0){
      if(Object.keys(cartObj).length==0){
        console.log('##############checkitycheckcheck###################################')
      }
      console.log('cartObj',cartObj)
      setCart()

    }else{
      console.log('cartObj change but no user ',user)
    }
  },[cartObj,user])

  const setCart = async()=>{
    // check if different
    console.log('**********set cart*********************',cartObj)
    let same=true;
    if(Object.keys(cartObj).length<=0){
      console.log('check 1')
      same=false
    }else if(Object.keys(userData['cart']).length<=0){
      console.log('check 2')
      same=false
    }
    if(userData['cart']==undefined){
      console.log('check 3')
        same=false
    }else{

      Object.keys(userData['cart']).forEach((item,index)=>{
        if(cartObj[item]==undefined){
      console.log('check 4')
          same=false;
        }else if(userData['cart'][item]['qty']!=cartObj[item]['qty']){
      console.log('check 5')
          same=false;
        }else if(userData['cart'][item]['sellerIndex']!=cartObj[item]['sellerIndex']){
      console.log('check 6')
          same=false;
        }
      })
    }
    if(!same){
      console.log('not same',cartObj)
      try{
        await updateDoc(doc(db,'users',user.uid), {'cart':cartObj})
        console.log('cart should be set')
      }catch(err){
        alert(err.message)
      }
    }

  }

  useEffect(()=>{
    console.log('userData',userData['cart'])
  },[userData])

  

  return (
    <Router>
      <div style={{width: "100%"}} className="stickPos" ref={navbarRef}>
        
        <Header
          expanded={expanded}
          setExpanded={setExpanded}
          header={header}
          setShowOutlet={setShowOutlet}
          auth={auth}
          setShowLogin={setShowLogin}
          cartObj={cartObj}
          user={user}
          setSelected1={setSelected1}
        />
        
        </div>

        {/*{showLogo && <SpinningLogo />}*/}
      <Routes>
        <Route path='/' element={
          <Main 
            setShowLogo={setShowLogo} 
            parallax={parallax} 
            selected1={selected1} 
            setSelected1={setSelected1} 
          />
        }>
        <Route path=':selected2' element={
          <Main 
            setShowLogo={setShowLogo} 
            parallax={parallax} 
            selected1={selected1} 
            setSelected1={setSelected1} 
          />

        } />
          </Route>
        
        <Route path='/Shop' element={ 
            <Shop 
              user={user}
              db={db}                   
              setShowOutlet={setShowOutlet}
              showOutlet={showOutlet}
              
            />
        } >

          <Route path=':productId' element={
            <Product 
              user={user}
              db={db}       
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              userData={userData}
              setCartObj={setCartObj}
              openDrop={openDrop}
              setOpenDrop={setOpenDrop}
            />
          } >
            <Route index element={<Product 
              user={user}
              db={db}       
              auth={auth}
            />} />


          </Route>
        </Route>

        <Route path='/Cart' element={ 
            <Cart 
              user={user}
              db={db}       
              cartObj={cartObj}
              setCartObj={setCartObj}
              userData={userData}
              setShowLogin={setShowLogin}     
              
            />
        } />
        <Route path='/Checkout' element={ 
            <Checkout 
              user={user}
              db={db}       
              auth={auth}
              setShowLogin={setShowLogin}     
              userData={userData}
              setCartObj={setCartObj}
              cartObj={cartObj}
            />
        } />

        <Route path='/Success' element={ 
            <Success 
              user={user}
              db={db}       
              auth={auth}
              setShowLogin={setShowLogin}     
              userData={userData}
              setCartObj={setCartObj}
              cartObj={cartObj}
            />
        } />
        <Route path='/Quote' element={ 
            <Quote 
              user={user}
              db={db}       
              auth={auth}
              setShowLogin={setShowLogin}     
              userData={userData}
              setQuoteObj={setQuoteObj}
              quoteObj={quoteObj}
              company={company}
            />
        } />
        
        {/*<Route exact path='/CurrentDream/:dreamId' render={(props) => <CurrentDream dreamRef={dreamRef} buttonsRef={buttonsRef} dreamBody={dreamBody} user={user} doc={doc} setDoc={setDoc} setPlaying={setPlaying} playing={playing} setFinishedSentences={setFinishedSentences} online={online} setOnline={setOnline} setShowLogo={setShowLogo} sentenceRef={sentenceRef} {...props} /> } />*/}
      </Routes>
      <div>
      {(showLogin) &&
        <LoginModal
          user={user}
          showLogin={showLogin} 
          setShowLogin={setShowLogin}
          auth={auth}
          createUserWithEmailAndPassword={createUserWithEmailAndPassword}
          signInWithEmailAndPassword={signInWithEmailAndPassword}
          db={db}
          setDoc={setDoc}
          doc={doc}
          sendEmailVerification={sendEmailVerification}
          sendPasswordResetEmail={sendPasswordResetEmail}
        />
      }
       
      </div>
    </Router>
  );
}

 

export default App;
